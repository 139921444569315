export default {
  welcome: "Welcome",
  common: {
    action: {
      default: "Default",
      noDefault: "No default price",
      cancel: "Cancel",
      cancelsOn: "Cancels on",
      clear: "Clear",
      close: "Close",
      create: "Create",
      delete: "Delete",
      select: "Select",
      selectAll: "Select All",
      enter: "Enter",
      retry: "Retry",
      resetPassword: "Reset Password",
      sendInstructions: "Send instructions",
      notSupported: "Incorrect File Type",
      noImage: "No Image to Delete",
      editUser: "Edit User",
      editNickname: "Edit Nickname",
      archivePrice: "Archive Price",
      deleteUser: "Delete User",
      email: "Email",
      fullName: "Full Name",
      save: "Save",
      add: "Add",
      addMore: "Add More",
      addPrice: "Add Price",
      amount: "Amount",
      name: "Name",
      customer: "Customer",
      location: "Location",
      formStatus: "Form Status",
      status: "Status",
      titleAndDescription: "Title & Description",
      metadata: "Metadata",
      type: "Type",
      submit: "Submit",
      skip: "Skip",
      required: "Required",
      created: "Created",
      added: "Added",
      text: "Text",
      transactionType: "Transaction Type",
      signed: "Signed",
      form: "Form",
      number: "Number",
      selection: "Selection",
      updated: "Updated",
      export: "Export",
      confirmExport: "Confirm Export",
      details: "Details",
      notAvailable: "Not Available",
      description: "Description",
      address: "Address",
      firstName: "First Name",
      lastName: "Last Name",
      phone: "Phone",
      optional: "Optional",
      id: "ID",
      deleteConfirm:
        "Are you sure you want to delete this user? This action cannot be undone.",
      deactivateUserConfirm: "Are you sure you want to deactivate this user?",
      activateUserConfirm: "Are you sure you want to activate this user?",
      deactivate: "Deactivate",
      activate: "Activate",
      reset: "Reset",
      addLocation: "Add Location",
      editLocation: "Edit Location",
      editPricing: "Edit Pricing",
      deleteLocation: "Delete Location",
      addTerminal: "Add Terminal",
      addReader: "Add Reader",
      editTerminal: "Edit Terminal",
      deleteTerminal: "Delete Terminal",
      favorite: "Favorite",
      createPayment: "Create Payment",
      exportReport: "Export Report",
      editPayment: "Edit Payment",
      sendPayment: "Send Payment",
      viewDetails: "View Details",
      remove: "Remove",
      addAsFavorite: "Add as Favorite",
      addFavorite: "Add Favorite",
      activateStripe: "Activate Stripe",
      stripeActivated: "Stripe activated",
      addANewLocation: "Add a location",
      addANewTerminal: "Add a terminal",
      showMore: "Go to Stripe Invoices",
      setAsDefault: "Set as Default Price",
      edit: "Edit",
      for: "for",
      redirecting: "Redirecting",
      actions: "Actions",
      upgrade: "Upgrade",
      value: "Value",
      next: "Next",
      prev: "Prev",
      previous: "Previous",
      send: "Send",
      sendReceipt: "Send Receipt",
      sms: "SMS",
      loading: "Loading",
      addCustomer: "Add Customer",
      editCustomer: "Edit Customer",
      deleteCustomer: "Delete Customer",
      productDetails: "Product Details",
      priceInformation: "Price Information",
      image: "Image",
      skipped: "Skipped",
      saveProduct: "Save Product",
      upload: "Upload",
      price: "Price",
      nickname: "Nickname",
      sku: "SKU",
      skuWarning: "Once a SKU has been set, it cannot be changed",
      skuLocked: "SKU cannot be modified after being set",
      skuRequired: "SKU is required and must be unique across your entire catalog",
      skuExists: "SKU already exists in your catalog, please enter a unique SKU",
      priceSlotAdded: "Price Slot Added!",
      priceSlotRemoved: "Price Slot Removed!",
      productCreated: "Product Created!",

      defaultPrice: "Default Price",
      nicknameInternalUse: "For internal use only",
      USD: "USD",
      addAnotherPrice: "Add Another Price",
      addProduct: "Add Product",
      addNameValue: "Please Enter a Name",
      errorMakingProduct: "Error during product creation",
      activeStatus: "Active Status",
      clearFilters: "Clear Filters",
      enterName: "Enter Name",
      applyFilter: "Apply Filter",
      enterDescription: "Enter Description",
      both: "Both",
      active: "Active",
      inactive: "Inactive",
      page: "Page",
      dateCreated: "Date Created",
      notFound: "Not Found",
      green: "green",
      grey: "grey",
      selectLimit: "Select Limit",
      selectCustomer: "Select Customer",
      editPrice: "Edit Price",
      apply: "Apply",
      editProduct: "Edit Product",
      editName: "Edit Name",
      editDescription: "Edit Description",
      savePrice: "Save Price",
      createProduct: "Create Product",
      noResultsFound: "No Results found",
      priceAmount: "Price Amount",
      enterAPriceAmount: "Enter a Price Amount",
      cannotDeactivateDefaultPrice: "Cannot Deactivate Default Price",
      createAProductInstantly:
        "Your product catalog is empty. Begin by adding a new product to display here.",
      priceIsAlreadySetAsDefault: "Price is already set as the Default Price",
      priceMustBeActive: "Only active prices can be set to default",
      paymentLink: "Payment Link",
      copyAndShare:
        "Copy and share to start accepting payments with this link.",
      quantity: "Quantity",
      adjQuantity: "Adjustable Quantity",
      and: "and",
      moreFor: "more for",
      activeLC: "active",
      yes: "Yes",
      no: "No",
      downloadImage: "Download Image",
      downloadQRImage: "Download QR",
      CopyImage: "Copy Image",
      CopyQRCode: "Copy QR",
      sendToTerminal: "Buy",
      selected: "selected",
      saveAndContinue: "Save & Continue",
      defaultWithBrackets: "(Default)",
      total: "Total",
      subTotal: "Subtotal",
      tax: "Tax",
      discount: "Discount",
      source: "Source",
      editDetails: "Edit Details",
      you: "You",
      archive: "Archive",
      lastUpdated: "Last Updated",
      paymentLowercase: "payment",
      to: "to",
      searchCustomers: "Search Customers",
      copiedToClipboard: "Copied to clipboard!",
      override: "Override",
      resetToDefault: "Reset to Default",
      view: "View",
      importProducts: "Import Products",
      bulkAdjustInventory: "Bulk Adjustment",
      downloadTemplate: "Download the Nectar Pay product template CSV",
      populateTemplate: "Populate the CSV with your products details",
      uploadFile: "Upload your completed CSV file",
      productsImported: "{count} products imported successfully",
      downloadTemplateDescription: "Download our template to ensure your product data is formatted correctly for import.",
      downloadInventoryTemplateDescription: "Download our CSV template to prepare your inventory adjustments.",
      downloadTemplateButton: "Download Template",
      populateTemplateDescription: "Fill in the template with your product information. Products with the same identifier will be grouped together with different price options.",
      populateInventoryTemplateDescription: "Fill in the template with your inventory adjustments.",
      productNameDescription: "Product name and description",
      priceCurrency: "Price and currency",
      productStatus: "Product status (active/inactive)",
      saveCSVFormat: "Save your file in CSV format when complete.",
      uploadFileDescription: "Select your completed CSV file to import your products.",
      uploadInventoryFileDescription: "Upload your completed CSV file to adjust inventory.",
      selectCSVFile: "Select CSV file",
      csvFilePlaceholder: "Click to browse or drag and drop",
      onlyCSVSupported: "Only CSV files are supported",
      import: "Import",
      adjust: "Adjust Inventory",
      pleaseSelectCSV: "Please select a CSV file",
      onlyCSVAllowed: "Only CSV files are supported",
      invalidCSVFormat: "The CSV file format is invalid. Please check the template and try again.",
      missingRequiredFields: "Required fields are missing in your CSV. Please ensure all required fields are included.",
      failedToImport: "Failed to import products",
      failedToAdjustInventory: "Failed to adjust inventory",
      csvFieldsTitle: "Field Details",
      productIdentifierDesc: "A unique identifier used to group price rows under the same product",
      inventoryProductIdentifierDesc: "Unique identifier for the product (required)",
      productNameDesc: "The display name of the product",
      productDescriptionDesc: "A brief description of the product",
      productActiveDesc: "Indicates whether the product is active (true/false)",
      priceIdentifierDesc: "A unique SKU for each price option. Must be unique within this import file. This is a required field if has_inventory is true.",
      inventoryPriceSkuDesc: "SKU of the specific price option (required)",
      priceUnitAmountDesc: "The static price expressed in the smallest currency unit (e.g., cents)",
      priceActiveDesc: "Indicates whether the price is active (true/false)",
      priceNicknameDesc: "A human-friendly label for the price, helping to distinguish between different price levels or types",
      priceLowStockThresholdDesc: "The threshold at which this price option is flagged as low stock. When inventory falls below this number, a low stock alert will be triggered.",
      priceStartingStockAmountDesc: "The initial inventory quantity for this price option. This sets the starting stock level for the product variant.",
      hasInventoryDesc: "Indicates whether the product has inventory (true/false)",
      adjustmentQuantityDesc: "Quantity to adjust (positive to add, negative to remove)",
      adjustmentActionDesc: "The action to be taken on the inventory. Valid values include: 'add' (increase inventory), 'remove' (decrease inventory), or 'set' (replace inventory) (required)",
      adjustmentReasonDesc: "Reason for the adjustment (e.g., 'Restock', 'Damaged', 'Inventory Count')",
      adjustmentNotesDesc: "Additional notes about this adjustment (optional)",
      referenceCodeDesc: "A unique code to identify and track the adjustment operation for auditing and reference purposes (optional)",
      memoDesc: "Additional context or details about the adjustment (optional)",
      importNote: "Important:",
      productPriceImportBehavior: "Products with the same product_identifier will only be created once. Each row in your CSV will create a new price object, allowing you to add multiple price options for the same product.",
      inventoryAdjustmentBehavior: "Each row in your CSV will adjust the inventory for a specific price SKU. The adjustment_action field determines how the quantity is applied (add, remove, or set), and adjustment_quantity must always be a positive number.",
      uploading: "Uploading",
      processing: "Processing",
      importErrors: "Import Errors",
      importErrorsTitle: "Import Errors",
      importErrorsDescription: "The following errors were found in your CSV file:",
      fixErrorsAndRetry: "Please fix these errors in your CSV file and try again.",
      errorFixInstructions: "Please fix these errors in your CSV file and try uploading again.",
      gotIt: "Got It",
      downloadTemplateAgain: "Download Template Again",
      inventoryAdjusted: "Successfully adjusted {count} inventory items",
      confirmNavigation: "Confirm Navigation",
      returnToTransaction: "Return to Transaction",
      cancelTransaction: "Cancel Transaction",
      viewInventoryLog: "View Inventory Log",
      makeDefault: "Make Default",
      additionalInformation: "Additional Information",
      prices: "Prices",
    },
    cart: {
      cart: "Cart",
      addToCart: "Add",
      removeFromCart: "Remove from Cart",
      checkout: "Checkout",
      clearCart: "Clear Cart",
      noItems: "No items in cart",
      copyCart: "Copy Cart",
    },
    country: {
      canada: "Canada",
      unitedStates: "United States",
      US: {
        code: "US",
        currency: "usd",
      },
      CA: {
        code: "CA",
        currency: "cad",
      },
    },
    entity: {
      user: "user",
    },
    notification: {
      paymentReceived: "Payment Received",
    },
    drag: "Drag",
    here: "Here",
    image: "image",
    images: "images",
    file: "file",
    files: "files",
    browse: "Browse",
    preview: "Preview",
    uploadNew: "Upload New",
    or: "or",
    deleteMsg: "The {0} was deleted successfully",
    deactivateMsg: "The {0} was successfully deactivated",
    activateMsg: "The {0} was successfully activated",
    checkEmail: "Check your email",
    verifyEmail:
      "We have sent an email to {0} to confirm your email address and activate your account. Please check your inbox and follow the instructions in the email.",
    verifyEmailNote:
      "Note that the link provided in the email will expire in 24 hours.",
    resendEmailNote: "Didn't receive email? Check your spam filter or",
    resetPasswordNote:
      "Enter email associated with your account and we will send an email with instructions to reset your password.",
    items: "items",
    filter: {
      apply: "Apply Filter",
      clear: "Clear Filter",
      clearAll: "Clear All Filters",
      byProduct: "Product",
    },
    search: {
      products: "Search Products",
      noProductsFound: "No Products Found",
    },
    message: {
      additionalFieldsLater: "You can add more product details after creation.",
      exportWithFilters: "Your current filters will be applied to your export.",
      noActiveFilters: "No filters currently applied. All inventory items will be exported.",
      activeFilters: "Active Filters:"
    },
    label: {
      nickname: "Nickname",
      sku: "SKU",
      product: "Product",
      lowStock: "Low Stock Items Only",
      outOfStock: "Out of Stock Items Only",
      stockRange: "Stock Range",
      status: "Status"
    },
    status: {
      enabled: "Enabled",
      disabled: "Disabled",
      active: "Active",
      inactive: "Inactive"
    },
  },
  platform: {
    pos: "POS Terminal",
    account: "Already have an account?",
    newWindow: "Open in a new window",
    newAccount: "Need a new account?",
    changeSessionLoading: "Reloading the site with the new account selected...",
    loginLoading1: "Buzzing up something special for you...",
    loginLoading2: "Gathering the nectar of innovation...",
    loginLoading3: "Honeycombing through the final details...",
    loginLoading4: "Pollinating your app with the latest features...",
    loginLoading5: "Winging our way to readiness...",
    loginLoading6: "Making a beeline to completion...",
    loginLoading7: "Bees at work! Preparing your sweet experience...",
    loginLoading8: "Just a hive moment away...",
    loginLoading9: "Crafting a buzzworthy experience...",
    loginLoading10: "Fluttering through the final checks...",
    loginLoading11: "Building the buzz! Almost there...",
    loginLoading12: "Sweeping the hive for perfection...",
    loginLoading13: "Sprucing up the honey pot...",
    loginLoading14: "Fine-tuning our buzzers and whistles...",
    loginLoading15: "Perfecting our pollen technique...",
    inviteLoading: "We are taking the info from the token please wait...",
    logoutLoading: "You are leaving now hope see you soon again...",
    logout: "Logout",
    menu: {
      dashboard: "Dashboard",
      payments: "Payments",
      products: "Products",
      terminals: "Terminals",
      account: "Account",
      users: "Users",
      help: "Help",
      logout: "Logout",
      customers: "Customers",
      paymentLinks: "Payment Links",
      forms: "Forms",
      fleetManagement: "Fleet Management",
      management: "Management",
      settings: "Settings",
      PRODUCT_CATALOG: "Catalog",
      INVENTORY_MANAGEMENT: "Inventory Management",
      productCatalog: "Catalog",
      inventoryManagement: "Inventory Management",
      inventory: "Inventory",
      reports: "Reports",
    },
  },
  errors: {
    fieldRequired: "This is a required field",
    maxCharLimit: "Max {0} characters",
    email: "Invalid email",
    name: "Name cannot contain any special characters",
    password: "Password must be at least 8 characters long",
    agree: "You must agree to continue",
    matchingPasswords: "Both passwords must match",
    authName: "A full name must be provided",
    required: "Required",
    number: "Input must be a number.",
    minValue: "Input must be at least {0}",
    maxValue: "Input must be at most {0}",
    last4: "Input must be four numeric characters.",
    loginEmailNotVerified:
      "The email is not verified, check you inbox we send the verification again",
    emailExists: "Email already exist",
    refundMoreThan: "Refund cannot be more than ",
    unauthenticated: "User not authenticated",
    refundExceed: "Amount can't be more than ${0}",
    numberRange: "Simulated Quantity must be between 1 and 99",
    url: "Invalid URL",
    quantity: "Quantity must be greater than 0",
    characterLimit: "Limit must be greater than 0",
    nameRequired: "Please provide a name",
    provideConfirmationPageUrl: "Please provide a URL",
    httpsRequired: "URL must start with 'https://'",
    customMessageCannotBeEmpty: "Custom message cannot be empty",
  },
  navBar: {
    login: "Login",
    register: "Register",
    invite: "Invite",
    dashboard: "Dashboard",
    accountSetup: "Account Setup",
    billingPlan: "Billing and Plan",
    users: "User Management",
    readers: "Locations and Terminals",
    readerDetail: "Locations and Terminals",
    emailRedirect: "Email Redirect",
    location: "Location",
    payments: "Payments",
    createPayment: "Create Payment",
    processPayment: "Process Payment",
    paymentDetails: "Payment Details",
    account: "Account",
    resetPassword: "Reset Password",
    noTerminal: "Add a terminal to enable payments",
    customers: "Customers",
    customerDetails: "Customer Details",
    products: "Products",
    retrieveProduct: "Product Details",
    paymentLinks: "Payment Links",
    createProduct: "Create Product",
    paymentLinksDetail: "Payment Link Details",
    createPaymentLink: "Create Payment Link",
    productDetails: "Product Details",
    updatePaymentLink: "Update Payment Link",
    terminalForms: "Terminal Forms",
    currentAccount: "Current Account",
    settings: "Settings",
    inventoryManagement: "Inventory Management",
    inventory: "Inventory",
    reports: "Reports",
  },
  page: {
    signUp: {
      signIn: "Sign In",
      signUp: "Sign Up",
      fullName: "Full Name",
      email: "Email",
      password: "Password",
      confirmPassword: "Confirm Password",
      name: "Name",
      emailAddress: "Email address",
      newPassword: "New Password",
      companyName: "Company Name",
      country: "Country",
      getStarted: "Get Started",
      continue: "Continue",
      resend: "Resend",
      details: "Details",
      verifyEmail: "Verify Email",
      company: "Company",
      reenterPassword: "Re-enter Password",
    },
    login: {
      success: "Login successful",
      rememberMe: "Remember me",
      continueMessage: "Or",
      forgotPassword: "Forgot password?",
      google: "Continue with Google",
      emailVerificationSent:
        "Email verification sent! Please check your email and follow the steps to verify your account",
      couldNotFindAccount:
        "Couldn't find your NectarPay account. Try again with a different email",
      wrongPassword:
        "Wrong password. Try again or click Forgot password to reset it",
    },
    resetPassword: {
      saveNewPassword: "Save New Password",
      successResetPassword: "New password has been reset successfully",
      EMAIL_NOT_FOUND: "Email not found",
      errorResetPassword:
        "There was a problem resetting the new password. Try later",
      newPasswordNote:
        "Your new password must be different from previously used passwords.",
      recoverPasswordNote:
        "We have sent password recovery instructions to your email.",
      checkSpam:
        "If you don't see the email in your inbox, please check your spam folder.",
      error:
        "This email address is not associated with an account. Please check your email address or sign up for a new account.",
      resetPassConfirm:
        "You are resetting the password for this User. A temporary password will be generated and sent to their email address. Please ensure that they change their password upon logging in for security reasons.",
    },
    users: {
      error: {
        user_00: "The user already belong to the account",
        user_01: "You cannot delete yourself",
        user_02: "Owner cannot be deleted",
        user_03: "User does not belong to account",
        user_04: "UID or Email required",
        user_05: "Admins can adjust all roles on everyone except Owner",
        user_06: "Must be only one Owner per account",
        user_07: "Invalid Role or Account",
        user_08: "Owner role cannot be changed",
        user_09: "Email has not been previously invited",
        user_10: "User doesn't exists",
        user_11: "You cannot deactivate yourself",
        user_12: "Owner cannot be deactivated",
      },
      title: {
        allUser: "All Users",
        total: "total",
        inactive: "inactive",
        active: "active",
        invited: "invited",
        addUser: " Add User",
        name: "Name",
        accessLevel: "Access Level",
        itemSelected: "Items Selected",
        activeStatus: "Active Status",
      },
      invite: {
        add: "Invitation email was sent successfully",
      },
      userRoles: {
        owner: "Owner",
        admin: "Admin",
        staff: "Staff",
      },
      activate: "Activate User",
      deactivate: "Deactivate User",
    },
    locations: {
      locationName: "Location Name",
      location: "Location",
      locations: "Locations",
      sectionHint:
        "Utilize locations to oversee your readers and their activities by linking them to a specific physical operating site.",
      country: "Country",
      city: "City",
      addressLine1: "Address line 1",
      addressLine2: "Address Line 2",
      state: "State",
      province: "Province",
      postalCode: "Postal Code",
      address: "address",
      zip: "ZIP",
      deleteLocationConfirm:
        "Are you sure you want to delete this location? This action cannot be undone.",
      howToSetUp:
        "Configure Your Location: Customize Terminal Background & Set Up Tipping",
      setUpGuide:
        "With a few simple steps, you can tailor your terminal backgrounds to match your brand and set up a tipping feature that encourages a seamless checkout experience for your customers.",
      noLocation:
        "Set up your first location and reader to start taking payments",

      configuration: {
        dialog: {
          error: {
            conf_04:
              "Invalid param: wisepos_e, found: Splashscreen exceeds maximum dimensions for this device type: 720 x 1280, Invalid param wisepos_e",
          },
          title: "Location Configuration",
          success: "Configuration {0} successfully",
          tipping: {
            title: "Tipping ",
            amount: {
              title: "Amount ",
            },
            percentage: {
              title: "Percentage ",
            },
            smartTip: {
              title: "Smart Tip ",
            },
          },
          background: {
            title: "Background ",
            subtitle: "Set location splash screen",
            header:
              "This image is shown on each WisePOS E at this location when idle.",
            bulletTitle: "The image must be:",
            bullet1: "720W X 1280H",
            bullet2: "JPG, PNG, or GIF format",
            bullet3: "Less than 2MB for JPG and PNG",
            bullet4: "Less than 4MB for GIF",
            footer:
              "After uploading, it may take several minutes for the splash screen to appear.",
          },
          deleteConfigurationHint:
            "Configuration changes are not immediate, and could take a few minutes to propagate",
          removeAllConfigurations: "Remove All Configurations",
          smartTipHint:
            "Below this amount, fixed amounts will be displayed; above it, percentages will be displayed",
        },
      },
    },
    terminals: {
      error: {
        fav_00: "This terminal is already added to favorites.",
        fav_01:
          "Cannot remove from favorites because this terminal is not in your favorites list.",
        fav_02:
          "You can only add up to 5 terminals to your favorites. Please remove one from your favorites before adding a new one.",
        fullTerminalUsage:
          "You can't add a new terminal, please upgrade the plan or increase the quantity.",
      },
      terminal: "Terminal",
      terminals: "Terminals",
      terminalId: "Terminal ID",
      activeStatus: "Active Status",
      readerId: "Reader Id",
      model: "Model",
      serialNumber: "Serial Number",
      lastSeen: "Last seen",
      offline: "Offline",
      active: "Active",
      inactive: "Inactive",
      activate: "Activate",
      deactivate: "Deactivate",
      planUsage: "Plan usage",
      addedFav: "Added to Favorite",
      removedFav: "Removed from Favorite",
      order: {
        orderTerminal: "Order Terminal",
        followInstruction: "Please follow instructions to order",
        bbpos: "BBPOS WisePOS E",
        orderingInstructions: "Ordering Instructions",
        loginToDashboard: "Login to Dashboard",
        goToTerminalOrderPage: "Go to Terminal order page",
        placeOrder: "Place Order",
      },
      zeroTerminals: "0 Terminals",
      register: {
        registerTerminal: "Register Terminal",
        upgradeYourPlan: "Upgrade your plan to register a new terminal",
        editTerminal: "Edit Terminal",
        code: "Registration Code",
        terminalName: "Terminal Name",
        simulatedQuantity: "Simulated Quantity",
        hint: "Trouble finding your registration code? ",
        hint2:
          "To generate a new one, swipe right from the left edge of the reader screen to reveal a Settings button. Tap the Settings button and enter the admin PIN. From here, you can select the option to generate a new pairing code.",
        label: "apple-grape-orange",
        deleteConfirm:
          "Are you sure you want to delete this terminal? This action cannot be undone",
        noTerminal:
          "Please configure your terminal to commence the acceptance of payments at your specified location",
      },
    },
    payment: {
      metadata: {
        customerName: "Customer Name",
        customerEmail: "Customer Email",
        customer: "Customer",
      },
      exportError: {
        exp_00: "Range can not over 1 year",
        exp_01: "Can not export payments as csv",
      },
      reconciliationReportCreationError: {
        rec_00: "reconciliation report creating pdf error",
        cha_00: "search for charges error",
        ref_03: "list refunds error",
      },
      filtersError: {
        pay_04: "search paymentIntents error",
      },
      payment: "Payment",
      amount: "Amount",
      description: "Description (optional)",
      last4: "Last 4",
      paymentFailed: "Payment Failed",
      paymentSucceeded: "Payment Succeeded",
      paymentFailedMeg:
        "We're sorry, but it seems that your payment was not successful. Do you want to retry the payment? ",
      paymentSucceededMeg:
        "Congratulation! Your payment has been successfully processed!",
      sendToTerminal: "Send To Terminal",
      devPresentPayment: "[DEV]Present Payment",
      simulatePresentingPayment: "Simulate Presenting Payment",
      sendAgain: "Send Again",
      card: "card",
      cardPresent: "card_present",
      interacPresent: "interac_present",
      rawData: "Raw Data",
      reconciliation: "Reconciliation",
      selectSimulateCardToPay: "Select Simulated Card To Pay",
      tippingNotSupported:
        "Tipping has not been configured for this location / currency",
      surcharge: {
        surchargeDisclaimer:
          "A surcharge of { surchargeRate }% may be applied.",
        surchargeDisclaimerMode:
          "Terminal surcharge disclaimer is disabled. Ensure the customer is informed of the surcharge.",
        surchargeDisclaimerModeEnabled:
          "Surcharge disclaimer will be displayed on reader",
        surchargeEnabled: "Surcharging Enabled",
        surchargeDisabled: "Surcharging Disabled",
        surchargeDisclaimerModeAlways:
          "Your account settings require all transactions to be surcharged",
        message:
          "If you pay using a credit card, an additional credit card surcharge of {surchargeRate}% will be imposed.",
        surchargeDisclaimerRefund:
          "This transaction was surcharged. If your are refunding a partial amount, your refund amount will be calculated in proportion to the surcharge",
      },
      filter: {
        applyFilter: "Apply Filter",
        clearFilters: "Clear Filters",
        noResultsFound: "No Results Found",
        filterByMetadata: "Filter By Metadata",
        filterByAmount: "Filter By Amount",
        filterByPaymentStatue: "Filter By Payment Status",
        filterByCreationDate: "Filter By Creation Date",
        startDate: "Start Date",
        endDate: "End Date",
        timeZone: "Time Zone",
        est: "EST",
        utc: "UTC",
        minAmount: "Min Amount",
        maxAmount: "Max Amount",
        isEqualTo: "is equal To",
        isGreatThan: "is greater than",
        isLessThan: "is less than",
        isBeforeOrOn: "is before or on",
        isOnOrAfter: "is on or after",
        isBetween: "is between",
        product: "Product",
        price: "Price",
        filterByCustomer: "Filter by Customer",
        surcharge: "Include Surcharge",
      },
      deletedHint: {
        location: "This location no longer exists",
        reader: "This reader no longer exists",
        locationAndReader: "This reader & location no longer exist",
      },
      processPayment: {
        selectFromFav: "Select Terminal from Favorite",
        selectLocation: "Select Location",
        selectTerminal: "Select Terminal",
        enterAmount: "Enter Amount",
        showTipping: "Tipping Displayed",
        hideTipping: "Tipping Hidden",
        cancelPayment: "Cancel Payment",
        clearScreen: "Clear Terminal",
        goBack: "Go Back",
        createNewPayment: "Create New Payment",
        createNewTransaction: "Create New Transaction",
        showAllUpdates: "Show All Updates",
      },
      timeline: {
        timeline: "Timeline",
        payment_intent_succeeded: "Payment Details",
        payment_intent_payment_failed: "Retry ",
        paymentDetails: "Payment Details",
        loadMore: "Load more",
        paymentCreated: " Created",
        paymentCanceled: " Canceled",
        paymentFailed: " Failed",
        paymentRefunded: " Refunded",
        partially_refunded: " Partially Refunded",
        noteAdded: " Note Added",
        refundFailed: " Refund Failed",
        partialRefundFailed: " Partial Refund Failed",
        paymentSucceeded: " Successful",
        requireAction: " Requires Action",
        noTimeLine: "No transaction history at this time",
        noTimeLineStatusHint:
          "Create a payment to see a detailed timeline of payment related events",
        paymentProcessing: " Processing",
        paymentUpdated: " Updated",
        formSubmitted: " Form Submitted",
        formFailed: " Form Failed",
        transactionCompleted: " Transaction Completed",
        surchargeStatusIs: "Surcharge status is",
        surchargeAmount: ", with a surcharge amount of",
        youWillNotBeCharged: ", and no surcharge will be applied.",
        processingPaymentMethod: "Processing payment method...",
        noteAndTimeline: "Note & Timeline",
        note: {
          addNote: "Add Note",
          transactionNote: "Transaction Note",
          saveNote: "Save Note",
        },
      },
      tooltips: {
        cannotRemoveCustomer:
          "Customer cannot be removed from existing payment. Create a new payment if you would like to select a different customer.",
      },
    },
    accountSetup: {
      activateStripe: "Activate your new NectarPay affiliated Stripe account",
      activateStripeStepper: "Activate Stripe",

      addANewLocationDetails:
        "Before adding a terminal, add the location where the terminal will be used",
      addANewTerminalDetails:
        "Last step! Add a terminal to start collecting payments",
      skipAddingTerminal: "Skip for Now",
      setupStepSuccess:
        "Select next to continue setting up your Nectar account",
      setupCompleteStepper: "Setup Complete",
      setupComplete: "All done! You're ready to start accepting payments",
      goToDashboard: "Go to Dashboard",
      inactive: "Inactive",
      paymentsEnabled: "Payments Enabled",
      enablePayouts: "Enable Payouts",
      locationAdded: "Location Added",
      readerAdded: "Reader Added",
      complete: "Complete",
      stripeStatus: "Stripe Status:",
      continue: "Continue",
      inactiveDescription: "Inactive means that you cannot process payments",
      paymentsEnableDescription:
        "Payments Enabled allows you to process payments, but you are unable to receive payouts",
      completeDescription:
        "With status Complete, you are able to process payments and receive payouts",
      enablePayments: "Enable Payments",
      enablePayoutsDescriptor:
        "Stripe can take 1-3 business days to verify information collected for payouts.",
      ifInformationProvided:
        "If you have already provided this information, select continue.",
    },
    dashboard: {
      recentPayments: "Recent Payments",
      favoriteTerminals: "Favorite Terminals",
      noTerminals:
        "Looks like you have no favorite terminals, click here to get started.",
      addLocationAndTerminal:
        "With Stripe activated you can now add a location",
      addTerminalLandingCard:
        "Last step! Add a terminal to start collecting payments",
      noPayments: "It appears no payments have been processed yet.",
      initiatePayment: "Initiate a transaction to process your first payment.",
      addATerminal:
        "Add a terminal to your favorites for quick and easy access",
      help: {
        setupGuide: "How to Setup Guide",
        setupText:
          "New around here? Our guide will teach you everything you need to know!",
        orderTerminals: "Order Terminals",
        manageTeam: "Manage Team",
      },
      payments: {
        id: "Id",
        amount: "Amount",
        status: "Status",
        location: "Location",
        terminal: "Terminal",
        date: "Date",
        refund: "Refund",
        viewReceipt: "View Receipt",
        viewDetails: "View Details",
        sendToReader: "Send to Reader",
        cancelPayment: "Cancel Payment",
        cancelError: "Unable to cancel payment. Terminal information is missing.",
      },
      terminals: {
        confirmRemoveMessage:
          "Are you sure you want to remove this terminal from your Favorites?",
        removeTerminal: "Remove Terminal",
        addFavoriteTerminal: "Add Favorite Terminal",
        selectLocation: "Select Location",
        selectTerminal: "Select Terminal",
      },
    },
    account: {
      accountStatus: "Account Status",
      stripeAccount: "Stripe Account",
      accountStatusRequirements:
        "Visit your Stripe Onboarding to check for any incomplete requirements, or to keep track of your account review progress.",
      goToStripe: "Go To Stripe",
      completeOnboarding: "Stripe Onboarding",
      planDetails: "Plan Details",
      chargesEnabled: "Charges Enabled",
      payoutsEnabled: "Payouts Enabled",
      chargesDisabled: "Charges Disabled",
      payoutsDisabled: "Payouts Disabled",
      gotoDashboard: "Stripe Dashboard",
      paymentMethod: "Payment Method",
      successfulPurchase: "Success! Your subscription has been updated.",
      alreadyDowngraded:
        "Your subscription is already set to be canceled at the end of the billing period.",
      planOptions: "Plan Options",
      invoiceHistory: "Invoice History",
      viewAllInvoices: "View all Invoices",
      invoice: {
        subscribeMessage: "Subscribe to a paid plan to see invoice history",
      },
      payment: {
        contact: "Contact",
        subscribeMessage: "Subscribe to a paid plan to see payment method info",
        endingIn: "ending in",
      },
      plan: {
        plan: "Plan",
        billingCycle: "Billing cycle",
        cost: "Cost",
        planUsage: "Plan usage",
        readers: "readers",
        terminals: "terminals",
        oneFree: "1 Free",
        starter: "Starter",
        billedMonthly: "Billed monthly per reader",
        perTransaction: "Per transaction",
        starterFeature1: "1 user",
        starterFeature2: "0.25% application fee",
        starterFeature3: "1-2 day support response",
        starterFeature4: "Payments",
        starterPlanFee: "Fee based",
        liteFeature1: "Unlimited users",
        liteFeature2: "No application fee",
        liteFeature3: "1 hour support response",
        liteFeature4:
          "Payments & Upcoming Features: Product Catalogue, Payment Links",
        terminalNote:
          "1 Free Terminal included in plan. To add more terminals upgrade to Pro plan.",
        addTerminalNote:
          "Need additional Terminals? Customize your number here!",
        upgrade: "Upgrade",
        addTerminals: "Add Terminals",
        addMoreTerminals: "Add More Terminals",
        addTerminalMessage1:
          "We see that you're interested in expanding your terminal access. Adding more terminals can increase your productivity and make your operations even more efficient.",
        addTerminalMessage2:
          "Please note that each <b>additional terminal will cost $15 per month.</b> These charges will be added to your current subscription.",
        addTerminalMessage3:
          "The good news is that any changes you make will take effect immediately, allowing you to enjoy the benefits of expanded access without delay.",
        addTerminalMessage4:
          "Please be aware that you also have the option to decrease the number of terminals if necessary.",
        month: "Monthly",
        downgrade: "Downgrade",
        downgradeTitle: "Considering Downgrading to Our Starter Plan?",
        planIncludes: "Plan includes",
        downgradeExpect: "What to Expect with the Starter Plan",
        downgradeFeature1: "One Active Terminal",
        downgradeFeature1Text:
          "With the Starter plan, you will be able to keep only one active POS terminal. If you have multiple terminals on your Lite plan, you will need to select which one you wish to remain active.",
        downgradeFeature2: "Standard Transaction Fees",
        downgradeFeature2Text:
          "While the Lite plan offers a reduced transaction fee of 0.5%, the Starter plan comes with our standard 1% transaction fee.",
        downgradeFeature3: "Regular Support",
        downgradeFeature3Text:
          "While we always aim to assist you as promptly as possible, the response time for our support team is typically longer for Starter plan users compared to the priority support provided with the Lite plan.",
        downgradeMessage:
          "Remember, you can always upgrade back to the Lite plan whenever you're ready.",
        downgradeMessage2:
          "Before proceeding, please consider if the downgrade aligns with your current business needs. If you're unsure, our support team is always ready to assist you in understanding the best plan for your business situation.",
        readyToDowngrade: "Are You Ready to Downgrade?",
        updateTerminals: "Update Terminals",
        upgradeTitle: "Ready to Boost Your Experience?",
        upgradeSubtitle: "Upgrade to Our Lite Plan Today!",
        upgradeBenefits: "Benefits of Upgrading to Lite Plan*",
        upgradeFeature1: "Additional Terminals",
        upgradeFeature1Text:
          "With the Lite plan, you can get access to more terminals, helping you manage increased customer demand efficiently.",
        upgradeFeature2: "Priority Support",
        upgradeFeature2Text:
          "Enjoy our priority support! Our dedicated team will respond to your queries and issues within 2-3 business days, ensuring that you're never stuck waiting for long.",
        upgradeFeature3: "Lower Transaction Fees",
        upgradeFeature3Text:
          "Cut down your costs significantly with our lower transaction fees. By upgrading, your transaction fees will be reduced to just 0.5%!",
        upgradeFeature4: "Define Your Terminal Count",
        upgradeFeature4Text:
          "Your business, your choice. We empower you to customize your Lite plan according to your specific needs. Feel free to decide the number of terminals you want to integrate - it's all up to you.",
        upgradeMessage:
          "*Please note, with the Lite plan, there's a monthly fee per terminal.",
        upgradeMessage2:
          "Don't let the free Starter plan limit your business potential. Upgrade to the Lite plan today and unlock new heights of growth and efficiency.",
        selectPlan: "Select Plan",
        currentPlan: "Current Plan",
        cancelNotice: "*Access to your canceled subscription ends ",
        0: "Free",
        1500: "$15 / reader",
        1000: "$10 / reader",
        2000: "$20 / reader",
      },
      invoicesHistory: {
        viewUpcomingInvoice: "View upcoming invoice",
        viewInvoice: "View invoice",
        upcomingInvoices: "Upcoming Invoices",
        noUpcomingInvoices: "You do not have any upcoming invoices.",
        paidInvoices: "Paid Invoices",
        invoice: "Invoice",
      },
      statuses: {
        unknown: "Unknown",
        restricted: "Restricted",
        complete: "Complete",
        enabled: "Enabled",
        restricted_soon: "Restricted soon",
        rejected: "Rejected",
        pending: "Pending",
        pending_enabled: "Pending",
        pending_disabled: "Pending",
        paid: "Paid",
        draft: "Draft",
        past_due: "Past Due",
        scheduled: "Scheduled",
        void: "Void",
        open: "Open",
        failed: "Failed",
        uncollectible: "Uncollectible",
      },
    },
    refund: {
      reason: {
        reason: "Reason",
        selectAReason: "Select a reason",
        duplicate: "Duplicate",
        fraudulent: "Fraudulent",
        requestedByCustomer: "Requested by customer",
        addMoreDetails: "Add more details about this refund",
        whyFraudulent: "Why is this payment fraudulent?",
        addReason: "Add a reason for this refund.",
      },
      refund: "Refund",
      refundPayment: "Refund Payment",
      refundPaymentHint:
        "Payment has been partially refunded ({0} {1} refunded).",
      refundHint:
        "Refunds take 5-10 days to appear on a customer's statement. Stripe's fees for the original payment won't be returned, but there are no additional fees for the refund.",
      refundForDebit:
        "This payment must be refunded in person since it is Interact Debit",
      refundSuccess: "Refund successful",
      refundTimeout: "Refund timed out. Please try again",
      refundError: "Error processing refund",
      listenerError: "Error monitoring refund status. Please check Dashboard for status.",
      clearReader: "Clear Reader",
      clearReaderHint: "If the reader is not responding, you can clear it and try again.",
      readerCleared: "Reader cleared successfully",
      clearReaderError: "Failed to clear reader",
      returnItems: "Number of items returned",
      adjustReturnQuantities: "Total number of items returned",
      returnQuantity: "Return Quantity",
      maxQuantity: "Max",
      previouslyRefunded: "previously refunded",
      restock: "Restock",
      returnStatus: "Return Status",
      noItemsSelected: "Please select items to return",
      amountMismatch: "Return items total is $${0}. You may want to match this amount for accurate inventory tracking.",
      itemDidNotUseInventory: "This item did not use any inventory"
    },
    usageStatus: {
      usageStatue: "Usage Status",
      totalTerminals: "Total Terminals",
      planUsage: "Plan usage",
      needMore: "Need additional Terminals? Add here!",
      free: {
        title:
          "1 Free Terminal included in plan. To add more terminals upgrade to Pro plan.",
      },
    },
    paymentDetails: {
      createdBy: "Created By",
      transactionDetails: "Transaction Details",
      applicationFee: "Application Fee",
      stripeFee: "Stripe Fee",
      net: "Net",
      refundReason: "Refund Reason",
      viewReceipt: "View Receipt",
      product: "Product",
      price: "Price",
      sendReceipt: "Send Receipt",
      receiptSentSuccess: "Receipts sent",
      additionalEmail: "Additional Email",
      additionalPhoneNumber: "Additional Phone Number",
      surcharge: "Surcharge",
    },
    customer: {
      noCustomerMessage:
        "It appears no customers have been created yet. Add customers or create customers during payment processing to see a list of customers.",
      createYourFirstCustomer: "Create your first customer",
      createNewCustomer: "Create New Customer",
      allCustomers: "All Customers",
      deleteCustomerMessage:
        "Are you sure you want to delete this customer? This action cannot be undone.",
      cusId: "Cus.ID",
      notes: "Notes",
      noNotesMessage: "No notes available",
      addNote: "Add Note",
      sendToCustomer: {
        loadMoreAndContinue: "Load More & Search Again",
        allPaymentLinksLoaded: "All Payment Links Loaded",
        searchLinks: "Search Links",
      },
    },
    paymentLinks: {
      message: {
        created: "Payment link created successfully",
        updated: "Payment link updated successfully",
        activated: "Payment link activated successfully",
        deactivated: "Payment link deactivated successfully",
        send: "Payment link sent successfully",
        customerCreated: "New customer created",
      },
      upcomingFeatures: {
        inventorySupport: "Inventory support for payment links coming soon! Stay tuned for future updates."
      },
      createACheckoutPageInstantly: "Create a checkout page instantly",
      createPaymentLink: "Create Payment Link",
      linkUrl: "Link URL",
      link: "Link",
      price: "Price",
      urlCopied: "Url copied to clipboard!",
      changeName: "Change Name",
      paymentLinkName: "Payment Link Name",
      scanToPay: "Scan to Pay",
      editLink: "Edit Link",
      copyLink: "Copy Link",
      searchLinks: "Search Links",
      nameHint: "This name is customer facing",
      qrCode: {
        qrCodeCopied: "QR code copied to clipboard!",
        downloadQrCode: "Download QR Code",
        qrCodeError: "Error downloading QR code",
      },
      addNewCustomer: "Add New Customer",
      updatingCustomerMessage: "Customer will be updated upon send",
      create: {
        selected: "Selected",
        product: "Product",
        selectProductsAndPrices: "Select Products & Prices",
        option: "Option",
        searchProducts: "Search Products",
        searchPlaceholder: "Beekeeper Suit - Medium",
        searchByKeywords: "Search by keywords",
        noPrice: "- No Price -",
        loadMore: "Load More",
        quantity: "Quantity",
        unitPrice: "Unit Price",
        quantityHint: "Let customers adjust quantity",
        between: "between",
        and: "and",
        removeTheProduct: "Remove the product",
        options: "Options",
        productTaxCode: "Product tax code:",
        quantityValidation: {
          notBlank: "Required",
          moreThanZero: "Must be more than zero",
          minMoreThanMax: "Minimum cannot be more than maximum",
        },
        customFields: {
          customFields: "Custom Fields",
          selected: "Selected",
          addAnotherOption: "Add Another Option",
          setLimits: "Set Limits",
          markAsOptional: "Mark as Optional",
          characters: "characters",
          addAnotherField: "Add Another Field",
          text: "Text",
          numbersOnly: "Numbers Only",
          dropdown: "Dropdown",
          atMost: "At most",
          atLeast: "At least",
          between: "Between",
          exactly: "Exactly",
          labelRequired: "Label is required",
          optionRequired: "Option is required",
        },
        callToAction: {
          callToAction: "Call to Action",
          asTheCallToAction: "As the call to action",
          pay: "Pay",
          donate: "Donate",
          book: "Book",
        },
        confirmation: {
          confirmationPage: "Confirmation Page",
          show: "Show",
          dontShow: "Don't Show",
          showConfirmationPage: "Show Confirmation Page",
          replaceDefaultWithCustomMessage:
            "Replace default with custom message",
          messagePlaceHolder:
            "Include any details you see fit, such as delivery information.",
          message: "Message",
          dontShowConfirmationPage: "Don't Show Confirmation Page",
          websiteURL: "Website URL",
          websiteUrlPlaceholder: "https://nectarpay.app.com",
          pleaseFillInTheRequiredFields: "Please fill in the required fields",
        },
        otherOptions: {
          otherOptions: "Other Options",
          limitTheNumberOfPayments: "Limit the number of payments",
          totalPayments: "Total Payments",
          changeDefaultMessage: "Change Default Message",
          addYourCustomMessage: "Add your custom message",
          MessageIsRequired: "Message is required",
          savePaymentDetailsForFutureUse: "Save payment details for future use",
          messageRequired: "Message is required",
          limitPayments: "Limit Payments",
          savePaymentDetails: "Save Payment Details",
          and: " and ",
          paymentsLimitedTo: "Payments limited to {0} payment{1}",
          customMessageApplied: "custom message set",
          limitAndCustomMessage:
            "Payments limited to {0} payment{1}, custom message applied",
        },

        summaryAndTotal: "Summary & Total",
        youHaveSelected: "You've selected {0} items",
        noPricesAvailable: "No prices available",
        error: {
          unableToCreatePaymentLink: "Unable to create payment link",
        },
      },
    },
    product: {
      productName: "Product Name",
      productDescription: "Product Description",
      minimumThreeCharacters: "Minimum 3 characters",
      enableInventory: "Enable Inventory",
      inventoryEnabled: "Inventory Enabled",
      inventoryDisabled: "Inventory Disabled",
      hasInventory: "Has Inventory",
      lowStockThreshold: "Low Stock Threshold",
      lowStockThresholdHint: "You'll be notified when stock falls below this number",
      negativeThresholdError: "Threshold cannot be negative",
      filter: {
        filterByStatus: "Filter by Product Status",
        filterByDescription: "Filter by Product Description",
        filterByName: "Filter by Product Name",
      },
      usingDefaultThreshold: "Using Default Threshold",
      resetToDefault: "Reset to Default",
      additionalInformation: "Additional Information",
      additionalFieldsLater: "You can add more product details after creation.",
    },
    createProduct: {
      pleaseEnterProductName: "Please enter a product name",
    },
    productDetails: {
      noResultsAndWaitMessage:
        "No results found",
      filterByNickname: "Filter by Price Nickname",
      filterBySku: "Filter by Price SKU",
      priceNickname: "Price Nickname",
      filterByStatus: "Filter by Price Status",
      priceAddedSuccess: "Price successfully added!",
      defaultPriceAddedSuccess:
        "Default price successfully added! It may take a moment for the price table to update",
      priceUpdatedSuccess: "Price successfully updated!",
      productUpdated: "Product updated!",
      productCreated: "Product created!",
    },
    terminalForm: {
      addTerminalForm: "Add Terminal Form",
      title: "Title",
      submitButton: "Submit Button",
      skipButton: "Skip Button",
      signature: "Signature",
      buttonStyle: "Button Style",
      choice: "Choice",
      createTerminalForm: "Create Terminal Form",
      selectYourStep: "Select your step Type",
      fiveSteps: "5 Steps",
      addStep: "Add Step",
      setFinishAction: "Set Finish Action",
      primary: "Primary",
      secondary: "Secondary",
    },
    settings: {
      surcharge: {
        surcharge: "Surcharge",
        surcharging: "Surcharging",
        displayDisclaimer: "Display Disclaimer Automatically",
        agree: "Agree",
        iUnderstand:
          "I understand that I am responsible for ensuring my customers are aware they are being surcharged.",
        enableHint: "By Pressing 'Enable' I agree to everything.",
        enableSurcharge: "Enable Surcharging",
        rate: "Rate",
        required: "Required",
        flexibleOn: "Optional (Default On)",
        flexibleOff: "Optional (Default Off)",
        enabled: "Enabled",
        disabled: "Disabled",
        surchargeSettingsSaved: "Surcharge settings saved successfully",
        surchargeSettingsUpdated: "Surcharge settings updated successfully",
        mode: "Mode",
      },
    },
  },
  accounts: {
    accountCreated: "Account Created",
    agreementText: "I agree to the",
    tos: "Terms of Use",
    newAccount: "New Account",
    noAccountsAvailable: "No Accounts Available",
    addNewAccount: {
      title: "New Account",
      companyName: "Company Name",
      country: "Country",
      email: "Email",
    },
  },
  status: {
    online: "Online",
    offline: "Offline",
    succeeded: "Succeeded",
    canceled: "Canceled",
    failed: "Failed",
    requires_payment_method: "Requires Payment Method",
    requires_action: "Requires Action",
    processing: "Processing",
    refunded: "Refunded",
    partially_refunded: "Partially Refunded",
    Active: "Active",
    Inactive: "Inactive",
    Invited: "Invited",
    incomplete: "Incomplete",
    requires_confirmation: "Requires Confirmation",
    archived: "Archived",
    active: "Active",
    complete: "Form Completed",
  },
  toolTipsMessage: {
    succeeded: "This payment is complete",
    canceled: "This payment is canceled",
    refunded: "This refund has been initiated",
    requires_action:
      "An additional action is required to complete this payment; Customer may be required to authenticate using 3D Secure",
    requires_payment_method: "The payment failed on checkout page",
    processing: "The payment was submitted to Stripe successfully",
    partially_refunded: "refund was initiated",
    incomplete: "The payment has not completed",
    formCompleted: "The form has been completed",
    formIncomplete: "The form has not been completed",
    requires_confirmation: "The payment requires confirmation",
    restricted_feature_message:
      "Upgrade to the lite plan to gain access to these features",
  },
  inventory: {
    adjustInventory: "Adjust Inventory",
    currentStock: "Current Stock",
    adjustmentType: "Adjustment Type",
    adjustmentAmount: "Adjustment Amount",
    referenceNumber: "Reference Number",
    referenceId: "Reference ID",
    memo: "Memo",
    memoPlaceholder: "Optional memo about this inventory adjustment",
    add: "Add Stock",
    remove: "Remove Stock",
    setTo: "Set Stock To",
    newStockWillBe: "New stock will be",
    amountMustBePositive: "Amount must be greater than zero",
    amountGreaterThanEqualToZero: "Amount must be greater than or equal to zero",
    cannotRemoveMoreThanStock: "Cannot remove more than current stock",
    adjustmentSuccessful: "Inventory adjusted successfully",
    adjustmentFailed: "Failed to adjust inventory",
    stockHeader: "Stock",
    stock: "Stock",
    checkout: "Checkout",
    hasInventory: "Has Inventory",
    lowStockThreshold: "Low Stock Threshold",
    threshold: "Threshold",
    filterByStock: "Filter by Stock",
    lowStock: "Low Stock",
    outOfStock: "Out of Stock",
    notEnabled: "Inventory not enabled",
    stockWarning: "Stock Warning",
    noWarning: "No Warning",
    stockRange: "Stock Range",
    stockRangeDisabledForOutOfStock: "Stock range is disabled for out of stock items",
    min: "Min",
    max: "Max",
    negativeValueError: "Value cannot be negative",
    minGreaterThanMaxError: "Min cannot be greater than Max",
    management: {
      title: "Inventory",
      description: "Manage your product inventory, track stock levels, and view inventory history.",
    },
    lowStockAlert: "You have items with low stock levels",
    outOfStockAlert: "You have items that are out of stock",
    viewLowStockItems: "View Low Stock Items",
    viewOutOfStockItems: "View Out of Stock Items",
    lowStockItemsTitle: "Low Stock",
    outOfStockItemsTitle: "Out of Stock",
    product: "Product",
    price: "Price",
    filterByProduct: "Filter by Product",
    productSearch: "Search Products",
    productAndPrice: "Product & Price",
    productSlashPrice: "Product/Price",
    filterByProductAndPrice: "Filter by Product & Price",
    viewPriceLog: "View Price Log",
    viewProductLog: "View Product Log",
    viewInventoryLog: "View Inventory Log",
    logTitle: "Inventory Log",
    priceLogTitle: "Price Inventory Log",
    productLogTitle: "Product Inventory Log",
    action: "Action",
    user: "User",
    details: "Details",
    amount: "Amount",
    dateRange: "Date Range",
    filterByDate: "Filter by Date",
    filterByUser: "Filter by User",
    selectUser: "Select User",
    filterByReference: "Filter by Reference ID",
    referenceSearch: "Search Reference IDs",
    filterByAction: "Filter by Action",
    selectAction: "Select Action",
    dateUser: "Date & User",
    initialStock: "Initial Stock",
    sku: "SKU",
    manageInventory: "Manage Inventory",
    inventorySettings: "Inventory Settings",
    exportDateRangeRequired: "A date range is required to export inventory logs. Please set a date range filter.",
    exportDateRangeTooLarge: "Date range cannot exceed 30 days. Please adjust your date range.",
    exportSuccessful: "Inventory log export completed successfully",
    inventoryChanges: "Inventory Changes",
    stockTooltip: "Physical inventory in stock",
    checkoutTooltip: "Items in checkout process but not yet purchased",
    flowDescriptionPurchase: "Items moved from stock to checkout",
    flowDescriptionRelease: "Items moved from checkout to finalized sale",
    flowDescriptionGeneric: "Both stock and checkout changed",
    from: "From",
    to: "To"
  },
  reports: {
    description: "Generate and download reports about your business activities.",
    purchaseReport: {
      title: "Purchases Report",
      description: "View and analyze purchases made through the NectarPay cart.",
      dateRange: "Date Range",
      today: "Today",
      yesterday: "Yesterday",
      thisWeek: "This Week",
      lastWeek: "Last Week",
      thisMonth: "This Month",
      lastMonth: "Last Month",
      customRange: "Custom Range",
      fromDate: "From Date",
      toDate: "To Date",
      reportMode: "Report Mode",
      includeItemDetails: "Include detailed item information",
      includeCanceledTransactions: "Include canceled transactions",
      generate: "Generate Report",
      success: "Report generated successfully!",
      error: "Failed to generate report"
    },
    returnsReport: {
      title: "Returns Report",
      description: "View and analyze customer returns and refunds processed with details about returned cart items.",
      dateRange: "Date Range",
      fromDate: "From Date",
      toDate: "To Date",
      reportMode: "Report Mode",
      generate: "Generate Report",
      success: "Returns report generated successfully!",
      error: "Failed to generate returns report",
      itemOptions: "Item Return Options",
      includeItemQuantities: "Include item quantities in report",
      includeReturnStatuses: "Include return statuses for items",
      returnStatuses: "Select return statuses to include",
      itemQuantity: "Item Quantity",
      returnStatus: "Return Status",
      statusOptions: {
        returned: "Returned",
        damaged: "Damaged",
        resalable: "Resalable",
        restocked: "Restocked",
        pending: "Pending Review"
      }
    },
    stripeFinancials: {
      title: "Financial Reports",
      description: "Access detailed financial reports, analytics, and insights on your Stripe dashboard.",
      viewButton: "View on Stripe"
    }
  },
};
