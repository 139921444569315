import { CartStore, CartItem } from "@/types/cart";
import { defineStore } from "pinia";
import router from "@/router";
import axios from "@/plugins/axios";
import { useSnackBarStore } from "@/stores/snackbar";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "@/plugins/firebase";
import { useAccountStore } from "@/stores/account";

export const useCartStore = defineStore("cart", {
  state: (): CartStore => ({
    loading: false,
    isVisible: false,
    items: [] as CartItem[],
    isAnimating: false,
    priceListeners: new Map(), // Map to store Firebase listeners by price ID
    productListeners: new Map(), // Map to store Firebase listeners by product ID
    products: new Map(), // Map to store product data by product ID
  }),

  getters: {
    total(): number {
      return this.items.reduce(
        (sum: number, item: CartItem) => sum + item.quantity * item.unitAmount,
        0
      );
    },
    
    // Getter to access product name by product ID
    getProductName(): (productId: string) => string {
      return (productId: string) => {
        return this.products.get(productId)?.name || 'Unknown Product';
      };
    },
    
    // Getter to access product image by product ID
    getProductImage(): (productId: string) => string {
      return (productId: string) => {
        return this.products.get(productId)?.images?.[0] || '';
      };
    }
  },

  actions: {
    // Set up listener for a price item in the cart
    setupPriceListener(priceId: string) {
      const accountId = useAccountStore().account?.id;
      if (!accountId || this.priceListeners.has(priceId)) return;
      
      const priceDocRef = doc(db, 'accounts', accountId, 'prices', priceId);
      
      const unsubscribe = onSnapshot(priceDocRef, (docSnapshot) => {
        if (docSnapshot.exists()) {
          const priceData = docSnapshot.data();
          
          // Find all cart items with this price ID and update them
          this.items.forEach((item, index) => {
            if (item.id === priceId) {
              // Update inventory-related properties only
              this.items[index] = {
                ...item,
                stockCount: priceData.stockCount || 0,
                checkoutCount: priceData.checkoutCount || 0,
                lowStockThreshold: priceData.lowStockThreshold || 5,
                isLowStock: this.isLowStock(priceData.stockCount, priceData.lowStockThreshold),
                hasInventory: priceData.hasInventory !== undefined ? priceData.hasInventory : true
              };
            }
          });
          
          // Set up a product listener if we have a product ID from the price data
          if (priceData.product && !this.productListeners.has(priceData.product)) {
            this.setupProductListener(priceData.product);
          }
        }
      }, (error) => {
        console.error(`Error listening to price ${priceId}:`, error);
      });
      
      // Store the unsubscribe function
      this.priceListeners.set(priceId, unsubscribe);
    },
    
    // Set up listener for a product and store in products map
    setupProductListener(productId: string) {
      const accountId = useAccountStore().account?.id;
      if (!accountId || this.productListeners.has(productId)) return;
      
      const productDocRef = doc(db, 'accounts', accountId, 'products', productId);
      
      const unsubscribe = onSnapshot(productDocRef, (docSnapshot) => {
        if (docSnapshot.exists()) {
          // Store the product data in the map
          const productData = docSnapshot.data();
          this.products.set(productId, productData);
          
          // No need to update cart items here - they will use the getter
        }
      }, (error) => {
        console.error(`Error listening to product ${productId}:`, error);
      });
      
      // Store the unsubscribe function
      this.productListeners.set(productId, unsubscribe);
    },
    
    // Helper function to check if an item is low on stock
    isLowStock(stockCount: number | null | undefined, threshold: number | null | undefined) {
      const count = Number(stockCount);
      const thresholdNum = Number(threshold);
      
      return count > 0 && thresholdNum > 0 && count < thresholdNum;
    },
    
    // Remove a price listener
    removePriceListener(priceId: string) {
      if (this.priceListeners.has(priceId)) {
        const unsubscribe = this.priceListeners.get(priceId);
        if (unsubscribe) {
          unsubscribe();
        }
        this.priceListeners.delete(priceId);
      }
    },
    
    // Remove a product listener
    removeProductListener(productId: string) {
      if (this.productListeners.has(productId)) {
        const unsubscribe = this.productListeners.get(productId);
        if (unsubscribe) {
          unsubscribe();
        }
        this.productListeners.delete(productId);
        
        // Also remove from products map
        this.products.delete(productId);
      }
    },
    
    // Check if any cart items are still using a product ID
    isProductStillInUse(productId: string) {
      return this.items.some(item => item.productId === productId);
    },
    
    // Clean up all listeners when component unmounts or is no longer needed
    cleanupAllListeners() {
      // Clean up price listeners
      for (const [priceId, unsubscribe] of this.priceListeners.entries()) {
        if (unsubscribe) {
          unsubscribe();
        }
      }
      this.priceListeners.clear();
      
      // Clean up product listeners
      for (const [productId, unsubscribe] of this.productListeners.entries()) {
        if (unsubscribe) {
          unsubscribe();
        }
      }
      this.productListeners.clear();
      
      // Clear product map
      this.products.clear();
    },

    triggerAnimation() {
      this.isAnimating = true;
      setTimeout(() => {
        this.isAnimating = false;
      }, 200);
    },

    removeItem(itemId: string) {
      const index = this.items.findIndex((item: any) => item.id === itemId);
      if (index !== -1) {
        const removedItem = this.items[index];
        this.items.splice(index, 1);
        this.triggerAnimation();
        
        // Check if this was the last item with this price ID
        const hasSamePriceId = this.items.some(item => item.id === removedItem.id);
        if (!hasSamePriceId) {
          this.removePriceListener(removedItem.id);
        }
        
        // Check if this was the last item with this product ID
        const hasSameProductId = this.items.some(item => item.productId === removedItem.productId);
        if (!hasSameProductId) {
          this.removeProductListener(removedItem.productId);
        }
      }
    },

    toggleIsCartVisible() {
      this.isVisible = !this.isVisible;
    },

    updateItemQuantity(itemId: string, change: number) {
      const item = this.items.find((item: any) => item.id === itemId);
      if (item) {
        if (change < 0 && item.quantity === 1) {
          return;
        }
        
        // If item doesn't track inventory or has sufficient stock, allow quantity increase
        if (change > 0 && (item.hasInventory === false || item.quantity < (item.stockCount || 0))) {
          item.quantity = item.quantity + change;
          this.triggerAnimation();
        } 
        // For quantity decrease, always allow
        else if (change < 0) {
          item.quantity = Math.max(1, item.quantity + change);
          this.triggerAnimation();
        }
      }
    },

    resetCart() {
      this.triggerAnimation();
      // Clean up all listeners before clearing the cart
      this.cleanupAllListeners();
      this.items = [];
    },

    async checkoutCart() {
      this.loading = true;
      try {
        const response = await axios.post("/cart", this.items);
        if (response.data.paymentIntentId) {
          //redirect to payment process page
          router.push({
            name: "processPayment",
            params: {
              id: response.data.paymentIntentId,
            },
          });
        }
      } catch (error: any) {
        useSnackBarStore().toastError(error.data);
      } finally {
        this.loading = false;
        this.resetCart();
      }
    },

    addItem(item: any) {
      // Get price information  
      const priceId = item.id;
      const priceName = item.nickname || '';
      const unitAmount = item.unit_amount || item.default_price?.unit_amount || 0;
      const currency = item.currency || item.default_price?.currency || 'usd';
      const sku = item.sku || item.default_price?.metadata?.sku || '';
      
      // Get product information - whether it's from a relatedProduct or directly in the item
      const productId = item.relatedProduct?.id || item.product || item.id;
      
      // Get inventory information
      const stockCount = item.stockCount || 0;
      const checkoutCount = item.checkoutCount || 0;
      const lowStockThreshold = item.lowStockThreshold || 5;
      const hasInventory = item.hasInventory !== undefined ? item.hasInventory : true;
      
      // Store initial product data in the map if we have it
      if (item.relatedProduct) {
        this.products.set(productId, item.relatedProduct);
      }
      
      // Create a consistent cart item structure where id is the price ID
      const cartItem: CartItem = {
        id: priceId,                // This is the price ID
        priceName,            // This is the price nickname
        productId,       // Reference to the product
        quantity: 1,
        description: item.relatedProduct?.description || item.description || '',
        unitAmount,
        image: item.relatedProduct?.images?.[0] || item.images?.[0] || '',
        currency,
        stockCount,
        checkoutCount,
        lowStockThreshold,
        isLowStock: this.isLowStock(stockCount, lowStockThreshold),
        sku,
        hasInventory
      };

      // Check if the item is already in the cart by price ID
      const itemInCart = this.items?.find(
        (i) => i.id === cartItem.id
      );

      if (itemInCart) {
        // Increment quantity for existing items
        itemInCart.quantity = itemInCart.quantity + 1;
        this.triggerAnimation();
      } else {
        // Add new item to cart
        this.items.push(cartItem);
        this.triggerAnimation();
        
        // Set up listeners for this item
        this.setupPriceListener(cartItem.id);
        this.setupProductListener(cartItem.productId);
      }
    },
    async getCartData(paymentIntentId: string) {
      this.loading = true;
      try {
        const response = await axios.get(`/cart/${paymentIntentId}`);
        return response.data;
      } catch (error: any) {
        useSnackBarStore().toastError(error.data);
      } finally {
        this.loading = false;
      }
    },

    async copyCart(paymentIntentId: string) {
      this.loading = true;
      try {
        const response = await axios.get(`/cart/${paymentIntentId}`);
        this.items = response.data;
        
        // Set up listeners for all items in the cart
        for (const item of this.items) {
          if (item.id && !this.priceListeners.has(item.id)) {
            this.setupPriceListener(item.id);
          }
          if (item.productId && !this.productListeners.has(item.productId)) {
            this.setupProductListener(item.productId);
          }
        }
        
        useSnackBarStore().toastSuccess({ message: "Cart items loaded" });
        return response.data;
      } catch (error: any) {
        useSnackBarStore().toastError(error.data);
      } finally {
        this.loading = false;
      }
    },
  },
});
